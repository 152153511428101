import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {MdWork, MdSchool} from "react-icons/md";
import {FaUmbrellaBeach} from "react-icons/fa";
import "./timeline.css";
import SEO from "./seo.js";

export default () => (
    <div className="timeline">

        <SEO
            title="Raywon's Timeline"
            description="Raywon's Timeline"
            pathname="/timeline"
        />

        <h1>My Timeline</h1>    

        <br/>
        
        <Container className="timeline-container">
            <Row>
                <Col>

                    <VerticalTimeline>
                        <VerticalTimelineElement
                            date="August 2020 - Present"
                            iconStyle={{ background: 'black', color: '#fff' }}
                            icon={<MdWork />}
                        >
                            <h3>Technical Architect</h3>
                            <a href="https://mathem.se"><h3 style={{color: 'black'}}><u>MatHem.se</u></h3></a>
                            <h4>Stockholm, Sweden</h4>
                        </VerticalTimelineElement>                        
                        <VerticalTimelineElement
                            date="May 2018 - August 2020"
                            iconStyle={{ background: '#1DBB2C', color: '#fff' }}
                            icon={<MdWork />}
                        >
                            <h3>DevOps Engineer</h3>
                            <a href="https://fortum.com"><h3 style={{color: 'green'}}><u>Fortum</u></h3></a>
                            <h4>Stockholm, Sweden</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            date="August 2016 - May 2018"
                            iconStyle={{ background: '#8E00C7', color: '#fff' }}
                            icon={<MdWork />}
                        >
                            <h3>DevOps Engineer</h3>
                            <a href="https://sinch.com"><h3 style={{color: 'purple'}}><u>Sinch</u></h3></a>
                            <h4>Stockholm, Sweden</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            date="February 2015 - November 2016"
                            iconStyle={{ background: '#F3F3F3', color: '#000000' }}
                            icon={<MdSchool />}
                        >
                            <h3>Master of Science</h3>
                            <a href="https://bth.se"><h3 style={{color: 'black'}}><u>Blekinge Institute Of Technology</u></h3></a>
                            <h4>Karlskrona, Sweden</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            date="2011 - 2014"
                            iconStyle={{ background: '#F3F3F3', color: '#000000' }}
                            icon={<MdSchool />}
                        >
                            <h3>Bachelor of Technology</h3>
                            <a href="https://www.jntuk.edu.in/"><h3 style={{color: 'black'}}><u>JNTU</u></h3></a>
                            <h4>Kakinada, India</h4>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            date="1993 - 2011"
                            iconStyle={{ background: '#F3F3F3', color: '#000000' }}
                            icon={<FaUmbrellaBeach />}
                        >
                            <h3>Just Chilling</h3>
                            <h4>School, Home</h4>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </Col>
            </Row>
        </Container>

    </div>
)