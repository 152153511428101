import React from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import Timeline from "../components/timeline.js"

export default () => (
    <div>
        <Header/>
        <Timeline/>
        <Footer />
    </div>
)
